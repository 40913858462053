import initHeader from './js/blog/header'
import initSubscribeInput from './js/blog/subscribe'
import initFooter from './js/blog/footer'


/* On page load */
document.addEventListener('DOMContentLoaded', function () {
  initHeader()
  initSubscribeInput()
  initFooter()

});
